* {
  --secondary: #FFD700;
}

.active-nav-item {
  color: var(--secondary);
}

.nav-item {
  color: white;
}

.nav-item .nav-item-label {
  font-size: 0px;
}

.nav-item-label {
  font-size: 13px;
  transition-property: font-size;
  transition-duration: 0.2s;
}
