.card {}

.card .second-layer {
  display: none;
}

.card:hover .second-layer {
  display: block;
}

.first-layer {}

.second-layer {
  cursor: pointer;
}
