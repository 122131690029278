* {
  /* create var primary with color #702963 */
  --primary: #702963;
}

.text-area-container {
  position: relative;
  border-width: 2px;
  border-radius: 4px;
  padding: 8px;
  border-color: rgba(0, 0, 0, 0.12);
}

.text-area-container:focus-within {
  border-color: var(--primary);
}

.text-area-container:focus-within .text-area-label {
  font-size: 11px;
  top: -8px;
  bottom: unset;
  background-color: white;
  padding: 1px 4px 1px 4px;
  color: var(--primary);
}

.text-area {
  border-width: 0px;
  display: block;
  appearance: none;
  width: 100%;
  background-color: transparent;
}

.text-area:focus {
  outline: none;
  color: black;
}

.text-area:not(:placeholder-shown) + .text-area-label {
  top: -8px;
  bottom: unset;
  font-size: 11px;
  background-color: white;
  padding: 1px 4px 1px 4px;
}

.text-area-label {
  position: absolute;
  transition-property: top font-size;
  transition-duration: 150ms;
  transition-timing-function: linear;
  transform-origin: 0%;
  top: 8px;
  left: 8px;
  pointer-events: none;
  /* z-index: -1; */
}
