/* Animation to slide in the chile */
.slide-in {
  animation: slide-in-animation 0.1s linear forwards;
}

@keyframes slide-in-animation {
  0% {
    opacity: 0;
    transform: translateY(25%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.dissolve {
  animation: dissolve-animation 0.1s linear forwards;
}

@keyframes dissolve-animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.expand {
  animation: expand-animation 0.2s linear forwards;
}

@keyframes expand-animation {
  0% {
    /* opacity: 0; */
    height: 0px;
  }

  100% {
    /* opacity: 1; */
    height: max-content;
  }
}

/* snackbar animation */
.snackbar {
  animation-name: snackbar-animation;
  animation-duration: 0.3s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}

@keyframes snackbar-animation {
  0% {
    opacity: 0;
    transform: translateY(24px);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

/* Floating Action Button animation */
.fab {
  animation: fab-animation 300ms linear forwards;
}

.fab p, .fab svg {
  opacity: 0;
  animation: fab-animation-p 75ms linear forwards;
  animation-delay: 300ms;
}

@keyframes fab-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fab-animation-p {
  0% {
    opacity: 1;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
