.grid-photo {
  animation: slide-in 0.1s ease-in-out;
}

.grid-photo:hover .grid-photo__overlay {
  display: flex;
}

.grid-photo__overlay {
  display: none;
  transform: translateY(100%);
  animation: slide-in 0.1s linear forwards;
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(25%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
